/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

.ms-6 {
  margin-left: ($spacer * 4) !important;
}

.comma-list {
  display: inline;
  list-style: none;
  padding: 0px;

  li {
    display: inline;
  }

  li::after {
    content: ", ";
  }

  li:last-child::after {
    content: "";
  }
}

.slash-list {
  display: inline;
  list-style: none;
  padding: 0px;

  li {
    display: inline;
  }

  li::after {
    content: " / ";
  }

  li:last-child::after {
    content: "";
  }
}

.date-picker-today {
  border-style: solid;
  border-width: thin;
}